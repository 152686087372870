var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[(_vm.routeFrom && _vm.routeFrom.name === 'SphereDetail')?_c('div',{staticClass:"back-link",on:{"click":function($event){return _vm.$router.push(_vm.routeFrom)}}},[_c('b-icon-chevron-left',{attrs:{"font-scale":"1.3"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('words.organisationgroup'))+" ")])],1):_c('div',{staticClass:"back-link",on:{"click":function($event){return _vm.$router.push({ name: 'Organisations' })}}},[_c('b-icon-chevron-left',{attrs:{"font-scale":"1.3"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('organisationDetail.organisationsList'))+" ")])],1),_c('b-container',{attrs:{"fluid":""}},[_c('b-overlay',{attrs:{"no-wrap":"","show":_vm.loading,"variant":'white',"opacity":0.7,"blur":'2px',"rounded":"sm"}}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-row',[_c('b-col',{staticClass:"detail-forms",attrs:{"cols":"8"}},[_c('h1',[_vm._v(_vm._s(_vm.currentOrganisation.display_name))]),_c('OrganisationForm',{attrs:{"organisation":_vm.currentOrganisation},on:{"update":_vm.updateFormData}})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"card-footer"},[_c('UsergroupThumbnail',{attrs:{"display-name":_vm.currentOrganisation.display_name,"organisation":_vm.currentOrganisation,"usergroup":_vm.currentUsergroup,"thumbnail":_vm.currentOrganisation.thumbnail}}),_c('div',{staticClass:"side-footer"},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return handleSubmit(_vm.saveOrganisation)}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.saveChanges'))+" ")])],1)],1)])],1)]}}])}),_c('b-row',{staticStyle:{"height":"auto"}},[_c('b-col',{staticClass:"users-table",attrs:{"cols":"11"}},[_c('h3',{staticClass:"line-header"},[_c('img',{attrs:{"src":require("@/assets/icons/face_user.svg"),"alt":"Icon face user"}}),_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.$tc('words.user', _vm.currentUsergroupMembersCount)))+" ")]),_c('p',{staticStyle:{"margin":"0 0 1em 1.2em","font-size":"1em"}},[_vm._v(" "+_vm._s(_vm.$t('organisationDetail.addUsersHelp'))+" "),_c('router-link',{attrs:{"to":{ name: 'Users' }}},[_vm._v(" "+_vm._s(_vm.$tc('words.user', _vm.currentUsergroupMembersCount))+" ")])],1),_c('SearchablePaginatedTable',{attrs:{"name":'organisationMembersTable',"margin":'1rem 0 1rem 1rem',"loading":_vm.loadingMembersTable,"searchable":false,"route-to":'UserDetail',"fields":_vm.membersFields,"rows":_vm.membersRows,"count":_vm.currentUsergroupMembersCount,"default-sorting":{
            sortedUp: true,
            sortedDown: false,
            sortedField: _vm.membersTableSorted.field
          },"sort-function":_vm.GET_USERGROUP_MEMBERS,"selectable":false},on:{"change-page":_vm.changeMembersTablePage,"sorted":_vm.setMembersTableSorted,"delete":_vm.removeUserFromOrganisation}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"11"}},[_c('h3',{staticClass:"line-header"},[_c('img',{attrs:{"src":require("@/assets/icons/face_user.svg"),"alt":"Icône sphère"}}),_vm._v(" Contacts ")]),_c('b-overlay',{attrs:{"show":_vm.loadingContactsTable,"variant":'white',"opacity":0.9,"blur":'2px',"rounded":"sm"}},[(!_vm.isCreatingContact)?_c('button',{staticClass:"add-button",staticStyle:{"margin-bottom":"1rem"},on:{"click":function($event){$event.preventDefault();_vm.isCreatingContact = true}}},[_c('b-icon-plus'),_vm._v(" Créer un contact ")],1):_vm._e(),(_vm.isCreatingContact)?_c('div',{staticClass:"add-contact"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var handleSubmit = ref.handleSubmit;
return [_c('DatasetContactForm',{attrs:{"current-contacts":_vm.contactsList,"contact":_vm.newContact,"usergroup-disabled":true},on:{"change":_vm.setNewContact,"delete":function($event){_vm.isCreatingContact = false}}}),_c('div',{staticClass:"contact-form-footer"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.addContact)}}},[_vm._v(" Ajouter ")])],1)]}}],null,false,2815987716)})],1):_vm._e(),_c('SearchablePaginatedTable',{attrs:{"name":'organisationContactsTable',"margin":'1rem 0 1rem 1rem',"searchable":true,"search-placeholder":_vm.$t('organisationDetail.contactsTable.search'),"search-function":_vm.SEARCH_CONTACTS_LIST,"fields":_vm.contactsFields,"rows":_vm.contactsRows,"count":_vm.contactsCount,"default-sorting":{
              sortedUp: true,
              sortedDown: false,
              sortedField: 'name'
            },"sort-function":_vm.GET_CONTACTS_LIST,"selectable":false,"allow-modification":true},on:{"change-page":_vm.changeContactsTablePage,"sorted":_vm.setMembersTableSorted,"modify":_vm.modifyContact,"delete":_vm.removeContact}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"11"}},[_c('h3',{staticClass:"line-header"},[_c('img',{attrs:{"src":require("@/assets/icons/sphere_planet.svg"),"alt":"Icon sphere planet"}}),_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.$tc('words.organisationGroup', 2)))+" ")]),_c('div',{staticStyle:{"margin-left":"1em"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('organisationDetail.addSphereLabel'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('organisationDetail.addSphereHelp'))+" ")]),_c('SearchUsergroups',{staticStyle:{"width":"60%"},attrs:{"placeholder":_vm.$t('searchbars.usergroupPlaceholder'),"types":['group-of-organisation'],"limit":100},on:{"select":_vm.addOrgToSphere}}),(_vm.selectedSpheres.length > 0)?_c('div',{attrs:{"id":"orga-spheres-container"}},_vm._l((_vm.selectedSpheres),function(selectedSphere){return _c('div',{key:selectedSphere.id,staticClass:"orga-sphere"},[_vm._v(" "+_vm._s(selectedSphere.display_name)+" "),_c('b-icon-x',{attrs:{"font-scale":"1.5"},on:{"click":_vm.removeOrgFromSphere}})],1)}),0):_vm._e()],1)])],1)],1),_c('UpdateContact',{attrs:{"open":_vm.isUpdatingContact,"contacts-list":_vm.contactsList,"contact":_vm.contactToUpdate},on:{"update":_vm.updateContact,"hide":function($event){_vm.isUpdatingContact = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }