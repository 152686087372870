<template>
  <div class="card-container">
    <b-overlay
      no-wrap
      :show="loading"
      :variant="'white'"
      :opacity="0.7"
      :blur="'2px'"
      rounded="sm"
    />
    <h5>
      {{ displayName ? displayName : $t('words.new') }}
    </h5>

    <ImportImage
      :type="'logo'"
      :name="'organisation-thumbnail'"
      :title="thumbnail ? thumbnail.name : null"
      :file="thumbnail ? thumbnail.url : null"
      :show-file-name="false"
      :show-input="false"
      class="thumbnail-selector"
      @update="setThumbnail"
      @delete="deleteThumbnail"
    />
    <p class="label-help">
      {{ $t('forms.datasets.thumbnail.help') }}
    </p>

    <div v-if="organisation">
      <span
        v-if="$route.name !== 'AddOrganisation'"
        style="margin-bottom: 0.5em; font-weight: 600;"
      >
        {{ organisation && organisation.usergroup && organisation.usergroup.members_count > 0 ?
          organisation.usergroup.members_count :
          $t('words.noneMasculine')
        }}
        {{ $tc('words.user', organisation && organisation.usergroup ? organisation.usergroup.members_count : 0) }}
      </span>
      <div v-if="usergroup && parents.length > 0">
        <span style="font-weight: 600;">
          {{ $t('cards.organisation.attachedToGroup') }}
        </span>
        <ul>
          <li
            v-for="parent of parents"
            :key="parent.id"
          >
            {{ parent.display_name }}
          </li>
        </ul>
      </div>
      <span v-else-if="$route.name !== 'AddOrganisation'">
        {{ $t('cards.organisation.attachedToNoGroup') }}
      </span>
    </div>
  </div>
</template>

<script>
import ImportImage from '@/components/ImportImage.vue';

import { mapState, mapActions } from 'vuex';

export default {
  name: 'UsergroupThumbnail',

  components: {
    ImportImage
  },

  props: {
    displayName: {
      type: String,
      default: null
    },
    usergroup: {
      type: Object,
      default: null
    },
    organisation: {
      type: Object,
      default: null
    },
    thumbnail: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      loading: false
    };
  },

  computed: {
    ...mapState('usergroups', [
      'usergroupsList'
    ]),
    parents() {
      return this.usergroupsList.filter(el => this.usergroup.parents.includes(el.id));
    },
  },

  methods: {
    ...mapActions('usergroups', [
      'GET_USERGROUP_THUMBNAIL',
      'SET_USERGROUP_THUMBNAIL',
      'DELETE_USERGROUP_THUMBNAIL'
    ]),

    async getThumbnail() {
      try {
        this.loading = true;
        await this.GET_USERGROUP_THUMBNAIL(this.usergroup.id);
        this.loading = false;
      } catch (err) {
        console.error(err);
        this.loading = false;
      }
    },

    async setThumbnail(e) {
      try {
        if (e) {
          const formData = new FormData();
          formData.append('file', e);
          if (this.usergroup && this.usergroup.id) {
            this.loading = true;
            await this.SET_USERGROUP_THUMBNAIL({
              id: this.usergroup.id,
              data: formData
            });
            this.loading = false;
          } else {
            this.$emit('set', formData);
          }
        } else {
          this.$emit('set', null);
        }
      } catch (err) {
        console.error(err);
        this.loading = false;
      }
    },
    async deleteThumbnail() {
      try {
        this.loading = true;
        await this.DELETE_USERGROUP_THUMBNAIL(this.usergroup.id);
        this.loading = false;
      } catch (err) {
        console.error(err);
        this.loading = false;
      }
    },
  }
};
</script>